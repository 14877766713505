<template>
  <section class="welcome fade">
    <v-img :src="logo" contain class="welcome__logo" alt="Свое"/>
    <p class="welcome__desc">{{$t('welcomeDesc')}}</p>

    <svg width="17" height="55" viewBox="0 0 17 55" fill="none" xmlns="http://www.w3.org/2000/svg"
     class="to-main" @click="goToMain"
    >
      <path d="M9.87096 1.47364L9.87097 49.3593L14.6573 44.5081C15.1914 43.9668 16.0595 43.9647
    16.5961 44.5034C17.1329 45.0422 17.1348 45.9178 16.6008 46.4591L9.47174 53.6846C8.93761
    54.2245 8.06547 54.2275 7.52956 53.686L0.399221 46.4592C-0.134772 45.918 -0.132922 45.0424
    0.403883 44.5036C0.940548 43.9648 1.80858 43.9669 2.34271 44.5082L7.12903 49.3593L7.12903
     1.47364C7.12903 0.709908 7.74281 0.0908207 8.5 0.0908207C9.25718 0.0908207 9.87096 0.709908
     9.87096 1.47364Z" fill="#343434"/>
    </svg>

  </section>
</template>

<script>
import { mapActions } from 'vuex';
import logo from '@/assets/img/logo.svg'

let timeout;
export default {
  name: 'Welcome',

  data() {
    return {
      logo,
    }
  },

  methods: {
    ...mapActions(['getBaseInfo']),
    goToMain() {
      this.$router.push('/main')
      clearTimeout(timeout)
    },
  },

  mounted() {
   timeout = setTimeout(() => this.goToMain(), 3000)
    this.getBaseInfo()
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
.welcome {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  &__logo {
    max-width: 400px;
    width: 100%;
    max-height: 130px;
    margin-bottom: 20px;
  }
  &__desc {
    font-size: 30px;
    line-height: 38px;
    max-width: 480px;
    text-align: center;
    color: $green;
    font-weight: 700;
  }
  .to-main {
    cursor: pointer;
    position: absolute;
    bottom: 80px;
    width: 100%;
    height: 54px;
    transition: .3s;
    &:hover {
      bottom: 60px;
      transition: .3s;
    }
  }
}

</style>
